<template>
    <div> 

        <v-toolbar v-if="show_toolbar"
            @click="ON_SEARCH(selling_model)" roundedd="" dark flat height="60"
            :color="active_local?'primary':'grey darken-1'" 
            class="ma-">
            <v-autocomplete  class="mr-2"
                v-model="search_item" 
                :disabled="inputLoading || !active_local || !active_model"
                :items="ItemsData" dense
                append-icon="search"
                outlined :rounded="false"
                :label="search_label_text"
                color="blue-grey lighten-2" 
                item-text="name"
                item-value="key" 
                :filter="(item, queryText, itemText) => { 
                    let index = (a,b)=>{
                            if(!a||!b){return false}
                            a = ''+a; b = ''+b
                            return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                        } 
                        let first = index(itemText,queryText)
                        let second = item?index(item.code,queryText):false
                        let third = item?index(item.brand_name,queryText):false
                        let forth = item?index(item.category_name,queryText):false
                        if(!item){return first}  
                        return first || second || third || forth 
                }"
                >
                <template v-slot:selection="data">
                    {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                    <template v-if="(typeof data.item !=='object')" >
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                    <v-list-item-avatar tile color="grey">
                        <img :src="data.item.image" v-if="data.item.image">
                        <v-icon v-else>local_offer</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.code_"></v-list-item-subtitle>
                        <v-list-item-subtitle v-html="data.item.category_name"></v-list-item-subtitle>
                        <v-list-item-subtitle v-html="data.item.brand_name"></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="selling_model=='Retail Price'">
                        {{ MBS.actions.money(data.item.retail_price) }}
                    </v-list-item-action>
                    <v-list-item-action v-else-if="selling_model=='Wholesale Price'">
                        {{ MBS.actions.money(data.item.wholesale_price) }}
                    </v-list-item-action>
                    </template>
                </template>
            </v-autocomplete>  
            <div v-if="!active_model" class="click-capture-overlay" @click="ON_SEARCH"></div>

            <span v-if="bar_type=='warehouse'">
                <!-- //action menu -->
                <v-menu  
                    v-model="menu_warehouse_mode"
                    :close-on-content-click="true" 
                    offset-y  offset-overflow
                    :nudge-width="90" rounded="xl"
                    >
                    <template v-slot:activator="{ on, attrs }">  
                        <v-btn  height="38"  v-on="on" class=" m-1"
                            ref="btn_selling_mode"
                            :disabled="!WarehouseSession?true:false"  
                                color="secondary">
                            <!-- {{warehouse_model?warehouse_model.name:'Select  Action'}} -->
                            <span v-if="warehouse_model"> <v-icon>{{warehouse_model.icon}}</v-icon> {{ warehouse_model.name }}</span>
                            <span v-else><v-icon>mdi-select</v-icon> Select Action</span>
                            <v-icon class="ml-2">arrow_drop_down</v-icon>
                        </v-btn> 
                    </template> 

                    <v-card  v-if="ud"  
                        color="lighten-1" class="">  
                        <v-card-title class="secondary white--text py-" dark>
                            Select Warehouse Action 
                        </v-card-title> 
                        <v-list dense> 
                            <div v-for="(model,index) in WarehouseModes" :key="index">
                                <v-list-item 
                                    :class="model == menu_warehouse_mode?'secondary--text':''" 
                                    @click="warehouse_model=model">
                                    <v-list-item-action class="ma-3 ml-1">
                                        <v-icon>{{model.icon}}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        {{model.name}}
                                    </v-list-item-content>
                                </v-list-item> 
                            </div>
                        </v-list>  
                    </v-card>  
                </v-menu>  
                <!-- //import menu -->
                <v-menu  
                    
                    v-model="menu_import_excel"
                    :close-on-content-click="true" 
                    offset-y  offset-overflow
                    :nudge-width="90" rounded="xl" >
                    <template v-slot:activator="{ on, attrs }">  
                        <v-btn    v-on="on" class=" ml-2" :loading="excel_loading"
                            ref="btn_selling_mode" :disabled="!WarehouseSession||!active_model"  
                            color="secondary">
                            <v-icon class="mr-2">mdi-microsoft-excel</v-icon>
                                {{import_model?import_model:'Select'}}
                            <v-icon class="ml-2">arrow_drop_down</v-icon>
                        </v-btn> 
                    </template> 

                    <v-card  v-if="ud"  
                        color="lighten-1" class="">  
                        <v-card-title class="secondary white--text py-" dark>
                            Select 
                        </v-card-title> 
                        <v-list dense> 
                            <div v-for="(model,index) in menuImportExcel" :key="index">
                                <v-list-item 
                                    :class="model == import_model?'secondary--text':''" 
                                    @click="SELECT_ACTION(model)">
                                    <v-list-item-action class="ma-2">
                                        <v-icon>{{model.icon}}</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        {{model.name}}
                                    </v-list-item-content>
                                </v-list-item> 
                            </div>
                        </v-list>  
                    </v-card>  
                </v-menu>  
            </span>
            <span v-else>
                <!-- //IF CARD -->
                <v-menu   v-if="SELECTED_CARD"
                    v-model="menu_selected_card"
                    :close-on-content-click="true" 
                    offset-y  offset-overflow
                    :nudge-width="90" rounded="xl"
                    >
                    <template v-slot:activator="{ on, attrs }" >   
                        <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                            <v-icon>mdi-credit-card-scan </v-icon>
                            <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                        </v-btn>  
                    </template> 
    
                    <v-card  v-if="ud"  
                        color="lighten-1" class="">  
                        <v-card-title class="secondary white--text py-" dark>
                            Selected Card
                        </v-card-title> 
                        <v-list dense> 
                            <div v-if="SELECTED_CARD">
                                <v-list-item>Card Holder: {{SELECTED_CARD.customer_name}}</v-list-item> 
                                <v-list-item>Card Number: {{SELECTED_CARD.code}}</v-list-item> 
                                <v-list-item>Expire Date: {{SELECTED_CARD.card_expire_date}}</v-list-item> 
                                <v-list-item>Balance: MWK{{MBS.actions.money(SELECTED_CARD.balance_amount)}}</v-list-item> 
                            </div>
                            <v-divider /> 
                            <v-list-item :class="'secondary--text text-center'" @click="DESELECT_CARD">
                                <v-layout justify-center>
                                    Clear
                                </v-layout>
                            </v-list-item> 
                        </v-list>  
                    </v-card>  
                </v-menu>  
                
                <!-- //IF ORDER -->
                <v-menu   v-if="ITEM_ORDER"
                    v-model="menu_item_order"
                    :close-on-content-click="true" 
                    offset-y  offset-overflow
                    :nudge-width="90" rounded="xl"
                    >
                    <template v-slot:activator="{ on, attrs }" >   
                        <v-btn  v-on="on" outlined class="mr-2 mb-1 pr-0"  > 
                            <v-icon>mdi-cart</v-icon>
                            <v-icon class="ml-2 ">arrow_drop_down</v-icon>
                        </v-btn>  
                    </template> 
    
                    <v-card  v-if="ud"  
                        color="lighten-1" class="">  
                        <v-card-title class="secondary white--text py-" dark>
                            Selected Order
                        </v-card-title> 
                        <v-list dense> 
                            <!-- <v-list-item @click="EDIT_ITEM_ORDER(ITEM_ORDER)">
                                <v-list-item-action><v-icon>edit</v-icon></v-list-item-action> 
                                <v-list-item-content>Update Order</v-list-item-content> 
                            </v-list-item>   -->
                            <v-list-item @click="PRINT_ITEM_ORDER(ITEM_ORDER)">
                                <v-list-item-action><v-icon>print</v-icon></v-list-item-action> 
                                <v-list-item-content>Print Order</v-list-item-content> 
                            </v-list-item> 
                            <!-- <v-list-item @click="PAY_ITEM_ORDER(ITEM_ORDER)">
                                <v-list-item-action><v-icon>mdi-cash</v-icon></v-list-item-action> 
                                <v-list-item-content>Pay Order</v-list-item-content> 
                            </v-list-item>   -->
                            <v-list-item @click="CONFIRM_DELETE_ITEM_ORDER(ITEM_ORDER)">
                                <v-list-item-action><v-icon>mdi-delete</v-icon></v-list-item-action> 
                                <v-list-item-content>Cancel Order</v-list-item-content> 
                            </v-list-item> 
                            <v-divider/> 
                            <v-list-item :class="'secondary--text text-center'" @click="CLEAR_ALL_DATA()">
                                <v-layout justify-center>
                                    Clear
                                </v-layout>
                            </v-list-item> 
                        </v-list>  
                    </v-card>  
                </v-menu>  
                
                <v-menu  
                    v-model="menu_selling_mode"
                    :close-on-content-click="true" 
                    offset-y  offset-overflow
                    :nudge-width="90" rounded="xl"
                    >
                    <template v-slot:activator="{ on, attrs }">  
                        <v-btn  v-on="on" class=" mb-1"
                            ref="btn_selling_mode"
                            :disabled="CompanySettings?CompanySettings.only_retail_model||CompanySettings.only_wholesale_model:false"  
                            color="secondary">
                            {{selling_model?selling_model:'Select'}}
                            <v-icon class="ml-2">arrow_drop_down</v-icon>
                        </v-btn> 
                    </template> 
    
                    <v-card  v-if="ud"  
                        color="lighten-1" class="">  
                        <v-card-title class="secondary white--text py-" dark>
                            Select Selling Mode 
                        </v-card-title> 
                        <v-list dense> 
                            <div v-for="(model,index) in sellingModels" :key="index">
                                <v-list-item :class="model == selling_model?'secondary--text':''" @click="selling_model=model">{{model}}</v-list-item> 
                            </div>
                        </v-list>  
                    </v-card>  
                </v-menu>  
            </span>

        </v-toolbar> 
         
        <v-card color=" " v-if="show_table"
            tile="" :height="height"
            flat=""  
            >  
            <!-- //NO SELECTED ITEMS -->
            <v-layout v-if="!MBS.actions.SIZE(SearchedItems)" fill-height align-center justify-center>
                <v-chip large color="b1" >
                    <v-icon class="mx-5" color="grey">mdi-tag</v-icon>
                    <span class="grey--text  mr-5">
                        No Item 
                    </span>
                </v-chip>
            </v-layout>
    
            <!-- //TABLE VIEW -->
            <div v-else-if="true" class=" "> 
                <v-simple-table  class="   " dense :height="height" fixed-header >
                    <!-- <template v-slot:default>
                    </template> -->
                    <thead class="primary">  
                        <tr class="font-weight-bold primary "> 
                            <th class="ma-0 pa-0 primary" style="width:'5px'"> 
                                <v-switch :inset="false" dark   style="height: ; margin: 5px;"
                                    v-model="item_multiple_select"
                                    label=""   hide-details
                                    class="ml-1"
                                ></v-switch> 
                            </th>
                            <th class=" text-left b primary  white--text">
                                ITEM NAME
                            </th> 
                            <th class="text-center1  b  primary white--text">
                                RETAIL PRICE  
                            </th>
                            <th class="text-center1  primary white--text">
                                WHOLESALE PRICE
                            </th>
                            <th class="text-center1  b  primary white--text"> 
                                STOCK
                            </th>  
                        </tr> 
                        <!-- <v-divider /> -->
                    </thead>
                    <tbody>
                        <tr class="pointer "  
                            v-for="(item,index) in SearchedItems"
                            :key="index" 
                            :class="item.checked?'checked':''"
                            @click="ON_SELECT_ITEM(item,index)"
                            @mousedown="startLongClick"
                            @mouseup="endLongClick"
                            >
                            <td class=" text-center with-divider-lr  ma-0 pa-0 table-background1" style="width:40px;"> 
                                <v-simple-checkbox @click.stop="" class="ma-0 pa-0"
                                    v-if="item_multiple_select" icon 
                                    color="primary" 
                                    v-model="item.checked" 
                                    />  
                                <!-- <v-btn @click.stop="CHECK_ITEM(item,index)" v-if="item_multiple_select" icon class="">
                                    <v-icon v-if="!item.checked">mdi-checkbox-blank-outline</v-icon>
                                    <v-icon v-else>mdi-checkbox-marked</v-icon>
                                </v-btn>  -->
                                <span v-else icon >
                                    <div >{{ index+1 }}</div>
                                    <!-- <v-icon v-if="item.expand">expand_more</v-icon>
                                    <v-icon v-else>chevron_right</v-icon> -->
                                </span>  
                            </td>
                            <td class=" with-divider ">
                                {{ item.name }}
                            </td> 
                            <td class=" with-divider text-right ">
                                {{ MBS.actions.money(item.retail_price) }}
                            </td>  
                            <td class="with-divider text-right ">
                                {{ MBS.actions.money(item.wholesale_price) }}
                            </td> 
                            <td class="with-divider text-right">
                                {{ toNumber(outlet_code?item[outlet_code+'_number_quantity']:item.number_quantity) }}
                            </td>   
                        </tr>
                        <div v-infinite-scroll="loadMore" :infinite-scroll-disabled="isLoading" infinite-scroll-distance="200">
                            <v-progress-circular v-if="isLoading" indeterminate></v-progress-circular>
                        </div>
                        <v-divider />
                    </tbody>
                </v-simple-table> 
            </div> 
        </v-card>

        <!-- //DIALOGS -->
        <div>
            <!-- EDIT ITEM DIALOG -->
            <v-dialog
                v-model="dialog_edit_selected_item"
                :scrollable="true"  
                :persistent="false" :overlay="true"
                max-width="800px"
                transition="dialog-transition" >
                <v-card height="100%" rounded="sm" color="b1" v-if="current_selected_item">
                    <v-card-title class="primary pa-2" >
                        <v-list-item dark>
                            <v-list-item-action>
                                <v-icon>mdi-tag</v-icon> 
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ current_selected_item.name }}</v-list-item-title> 
                                <v-list-item-subtitle>{{ current_selected_item.category_name }}</v-list-item-subtitle>  
                            </v-list-item-content> 
                            <v-list-item-action>
                                <v-layout align-center >
                                    <div class="mr-1 not-f3 mt-2">MWK</div>
                                    
                                    <span  class="not-f8 font-weight-bold"> 
                                        {{MBS.actions.money(CALCULATE_PRICE(current_selected_item))}} 
                                    </span>
                                </v-layout>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-title>
                    <div outlined class="mbs-borders mx-2 mt-2 mb-2 pa-2" :style="'--size:1px; --color:grey; --conner:3px'">
                        <form @submit.prevent="UPDATE_SELECTED_ITEM()">
                            <v-card-text class="b1">
                                <v-row>
                                    <v-col cols="12" sm="6" class="py-0"> 
                                        <div>{{current_selected_item.selling_model}}</div> 
                                        <v-text-field dense color="primary"   
                                            :disabled="CompanySettings?CompanySettings.edit_selling_price?false:true:true"
                                            filled outlined v-mask="currencyMask"
                                            v-model="current_selected_item.sold_price"  
                                            outline
                                        ></v-text-field>  
                                    </v-col>
                                    <v-col cols="12" sm="6" class="py-0 " >
                                        <div>Quantity (QTY)</div>
                                            <v-text-field dense autofocus
                                                filled outlined 
                                                v-model="current_selected_item.quantity"
                                                @focus="FOCUS_INPUT({value:'current_selected_item_quantity',ref:'ref_current_selected_item_quantity'})"
                                                outline
                                            ></v-text-field> 
                                    </v-col>
                                    
                                    <v-col cols="12" sm="12" class="py-0" v-if="CompanySettings?CompanySettings.allow_selling_discount:false" >
                                        <div>Item Discount</div>
                                            <v-text-field dense  
                                                :disabled="CompanySettings?CompanySettings.allow_selling_discount?false:true:true"
                                                filled outlined 
                                                v-model="current_selected_item.discount"
                                                outline
                                            ></v-text-field> 
                                    </v-col>
                                    <!-- <v-col   cols="12" sm="6" class="py-0 " v-if="CompanySettings?CompanySettings.allow_selling_discount:false">
                                        <div>Discount Percent (%)</div>
                                            <v-text-field dense 
                                            :disabled="CompanySettings?CompanySettings.allow_selling_discount?false:true:true"
                                                filled outlined 
                                                v-model="input_discount_percentage"
                                                outline
                                            ></v-text-field> 
                                    </v-col> -->
                                     
                                </v-row> 
                            </v-card-text> 

                             
                            <v-layout row wrap class="ma-0  mt-4">
                                <v-flex sm12 md6> 
                                    <v-card height="100%" hover class="mr-2 "> 
                                        <v-layout justify-center align-center fill-height class="mbs-border primary lighten-5  pa-2" :style="'--size:1px; --color:grey; --conner:3px'"> 
                                            <mbs-keyboard 
                                                :type="'number'"
                                                :row="'5'"
                                                :key_function="true"
                                                />
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                                <v-flex sm12 md6>
                                    <v-card height="100%" hover>
                                        <v-layout column  class="ma-0" fill-height justify-center align-center>  
                                            <v-spacer></v-spacer>
                                            <v-btn @click="CONFIRM_REMOVE_ITEM(current_selected_item)"
                                                class="my-2 font-weight-bold" 
                                                color="primary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">delete</v-icon>
                                                    <div class="not-f5 mt-1">Remove Item</div> 
                                                </v-layout>
                                            </v-btn>  
                                            <v-btn type="submit"
                                                class="my-2 font-weight-bold"   
                                                color="secondary" :height="vs.smAndDown?'60':'70'" width="96%">
                                                <v-layout justify-center align-center> 
                                                    <v-icon class="mr-2">mdi-upload</v-icon>
                                                    <div class="not-f5 mt-1">Update Item</div> 
                                                </v-layout>
                                            </v-btn>   
                                        </v-layout> 
                                    </v-card>
                                </v-flex> 
                            </v-layout>   
                        </form>
                    </div> 
                </v-card> 
            </v-dialog> 
            
            <!-- excel -->
            <mbs-excel-selector
                :notShow="false"
                @onFileSelected="EXCEL_FILE_SELECTED" 
                @onNoFileSelected="NO_EXCEL_FILE_SELECTED" 
                @onLoadingFile="LOADING_FILE" 
                @onError="ON_FILE_ERROR" 
                />  
            <mbs-excel-export 
                :file_name="excel_template.name"
                :sheet_name="excel_template.sheet_name"
                :data_items="Items?Items:excel_template.sample_data"
                :header_items="excel_template.headers"
            /> 
        </div>

    </div>
</template> 
<script> 

    import InfiniteScroll from 'vue-infinite-scroll';
    import DATA from "../../../plugins/DATA";
    import {mapState} from "vuex" 
    import { mdiReceiptTextArrowRightOutline } from '@mdi/js';
    const PAGE_NAME = "SELECT_ITEMS"

    export default {
        props:[
            'show_toolbar','show_table','bar_type','height','search_key',
            'items','color','textColor','opacity','title','text',
            'outlet_code','selected_card','item_order'],
        directives: {
            InfiniteScroll,
        },
        data(){
            return{
                show:false,   
                item_multiple_select:false,   
                dialog_edit_selected_item:false,   
                menu_selling_mode:false, 
                menu_selected_card:false, 
                menu_select_order_option:false, 
                menu_item_order:false, 
                menu_warehouse_mode:false,
                menu_import_excel:false, 
                current_selected_item:null,   
                input:{},   
                focused_field:null,
                input_discount_amount:null,   
                input_discount_percentage:null,   
                inputLoading:false,
                excel_loading:false,
                longClickTimer: null,
                longClickDetected: false,

                
                selectedRows: [],
                lastSelectedRow: -1,
                editingCell: null,

                pagination: null,
                paginatedItems: [],
                isLoading: false,
                currentPage: 1,
                totalPages: 1,
                pageSize: 20,

                sellingModels:["Retail Price","Wholesale Price"], 
                WarehouseModes:[
                    {code:1,name:"Receive Stock",values:DATA.ITEMS.STOCK_RECEIVES.value,icon:"mdi-package-variant-plus"},
                    {code:2,name:"Transfer Stock",values:DATA.ITEMS.STOCK_TRANSFERS.value,icon:"mdi-package-variant-minus"},
                    {code:3,name:"Send Quotation",values:DATA.ITEMS.QUOTATIONS.value,icon:mdiReceiptTextArrowRightOutline},
                    // {code:4,name:"Request Quotation",icon:mdiReceiptTextArrowLeftOutline}
                ], 
                menuImportExcel:[
                    {code:1,icon:"mdi-microsoft-excel",name:"Import Excel"},
                    {code:2,icon:"mdi-download",name:"Download Excel Template"},
                ],  
                selling_model:null,
                warehouse_model:null,
                import_model:null,
                search_item:'',
                excel_template:{
                    name:"STOCK RECEIVED TEMPLATE (v1)",
                    sheet_name:"items",
                    headers:[
                        {id:0,name:"code",value:"code",align:"center",show:true},      
                        {id:0,name:"name",value:"name",show:true},  
                        {id:0,name:"cost_price",value:"cost_price",show:true}, 
                        {id:0,name:"discount",value:"discount",show:true}, 
                        {id:0,name:"quantity",value:"quantity",show:true},  
                    ],
                    sample_data:[
                        {code:1,name:"Sample Item",cost_price:1000,discount:0.2,quantity:10},   
                    ],
                }, 
            }
        },
        created(){ 
            try {
                this.MBS.events.$on('ON_KEY_DOWN', this.ON_KEY_DOWN);   
                this.MBS.events.$on('MBS_KEYBOARD', this.MBS_KEYBOARD); 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted(){   
            this.loadMore();
            this.INITIALIZE_SELLING_MODE()
        },
        computed:{
            vs(){
                let vs = this.$vuetify.breakpoint 
                return vs
            }, 
            vs_width(){
                let vs = this.vs
                if(!vs){return null}
                return vs.width
            },
            vsh(){
                return this.vs.height
            },
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
                SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'],
                K_CONNECTOR: state=> state.items['K_CONNECTOR'],
            }),
            ...mapState({
                CompanySettings: state=> state.items[(DATA.ITEMS.COMPANY_SETTINGS.values).toUpperCase()], 
                CompanyQuickActions: state=> state.items[(DATA.ITEMS.COMPANY_QUICK_ACTIONS.values).toUpperCase()], 
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                ItemCategories: state=> state.items[(DATA.ITEMS.ITEM_CATEGORIES.values).toUpperCase()],
                
                DaysShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],
                JoinedDaysShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
                LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
                
               
                Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
                JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
                
                ItemOrders: state=> state.items[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                JoinedItemOrders: state=> state.join[(DATA.ITEMS.ITEM_ORDERS.values).toUpperCase()],
                LocalItemOrders: state=> state.items[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                JoinedLocalItemOrders: state=> state.join[(DATA.ITEMS.LOCAL_ITEM_ORDERS.values).toUpperCase()],
                ItemOrderOptions: state=> state.items[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                JoinedItemOrderOptions: state=> state.join[(DATA.ITEMS.ITEM_ORDER_OPTIONS.values).toUpperCase()],
                
                LocalWarehouseSessions: state=> state.items[(DATA.ITEMS.LOCAL_WAREHOUSE_SESSIONS.values).toUpperCase()], 
            }), 

            ItemsData(){
                let Items = this.Items
                let JoinedItems = this.JoinedItems
                return JoinedItems?JoinedItems:Items
            },
            UseableItems(){
                let items = this.items
                let ItemsData = this.ItemsData
                return ItemsData?ItemsData:items

            }, 
            SearchedItems(){
                let UseableItems = this.UseableItems
                let search_key = this.search_key
                if(!UseableItems){return null}
                if(!search_key){return UseableItems}
                let filtered_item = UseableItems.filter(item=>{
                    let search = this.MBS.actions.TEXT_UP(item.name)
                    if (!search) {
                        search=""
                    }
                    let index = search.indexOf(this.MBS.actions.TEXT_UP(search_key))
                    return index == -1?false:true
                })
                console.log(filtered_item,'filtered_item');
                return filtered_item

            },  
            CheckedItems(){
                const ItemsData = this.ItemsData
                if(!ItemsData){return null}
                let filtered = ItemsData.filter(item=>item.checked)
                return filtered
            },

            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup 
                return localCashup
            }, 
            WarehouseSession(){   
                let sessions = this.LocalWarehouseSessions 
                let company = this.SELECTED_COMPANY 
                if(!sessions || !company){return null}
                let local_company = sessions[0]
                let local_company_key = local_company?local_company.company_key:null
                let company_key = company?company.company_key:null
                if (local_company_key && company_key!=local_company_key) {
                    console.log('close........');
                    this.CLOSE_WAREHOUSE()
                }
                console.log(sessions,'sessions.5...');
                console.log(company,'company....');
                return sessions[0]
            }, 
            active_model(){   
                let bar_type = this.bar_type 
                let selling_model = this.selling_model 
                let warehouse_model = this.warehouse_model 
                if(bar_type=="warehouse"){
                    return warehouse_model
                }
                return selling_model
            }, 
            active_local(){   
                let cashup = this.LocalCashup 
                let session = this.WarehouseSession 
                let bar_type = this.bar_type 
                if(bar_type=="warehouse"){
                    return session
                }
                return cashup
            }, 
            search_label_text(){   
                let active_model = this.active_model 
                let bar_type = this.bar_type  
                if(!active_model){
                    if(bar_type=="warehouse"){
                        return "You Must Select Warehouse Action First"
                    }else{
                        return "You Must Select Selling Model First"
                    }

                }
                return "Search for Item"
            }, 
            SELECTED_CARD(){
                let card = this.selected_card
                return card
            }, 
            ITEM_ORDER(){
                let order = this.item_order
                return order
            }, 
            
              
        },
        methods:{ 
            async loadMore(event) {
                let items = this.SearchedItems
                let isLoading = this.isLoading
                let currentPage = this.currentPage
                let totalPages = this.totalPages
                let pageSize = this.pageSize
                let pagination = this.pagination
                let paginatedItems = this.paginatedItems

                console.log(event,1111111111);
               
                // if (this.isLoading || this.currentPage >= this.totalPages) return;

                console.log(222222);
                this.isLoading = true;

                try { 
                    this.pagination = this.MBS.actions.pagination(items,currentPage,pageSize) 
                    this.paginatedItems = this.pagination?this.paginatedItems.concat(this.pagination.list) :null
                    this.currentPage++
                    console.log(pagination,'newData......');
                     
                } catch (error) {
                    console.error('Error loading more data:', error);
                }

                this.isLoading = false;
            },
            handleKeyDown(event) {
                // if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
                //     event.preventDefault();
                // }
            },
            isEditing(rowIndex, columnIndex) {
            return this.editingCell && this.editingCell.row === rowIndex && this.editingCell.column === columnIndex;
            },
            startEditing(rowIndex, columnIndex) {
            this.editingCell = { row: rowIndex, column: columnIndex };
            this.$nextTick(() => this.$refs.editField.focus());
            },
            stopEditing(rowIndex, columnIndex) {
            this.editingCell = null;
            },
            selectRow(index) {
                if (this.lastSelectedRow !== -1 && this.$root.shiftKey) {
                    const start = Math.min(this.lastSelectedRow, index);
                    const end = Math.max(this.lastSelectedRow, index);
                    this.selectedRows = [];

                    for (let i = start; i <= end; i++) {
                    this.selectedRows.push(i);
                    }
                } else if (this.selectedRows.includes(index)) {
                    this.selectedRows.splice(this.selectedRows.indexOf(index), 1);
                } else {
                    this.selectedRows.push(index);
                } 
                this.lastSelectedRow = index;
            },


            startLongClick() {
                this.longClickTimer = setTimeout(() => {
                    this.longClickDetected = true;
                    this.item_multiple_select = !this.item_multiple_select;
                    console.log('Long click detected');
                }, 1000); 
            },
            endLongClick() {
                clearTimeout(this.longClickTimer);
                setTimeout(() => { 
                    this.longClickDetected = false;
                }, 10);
            },

            handleClick() {
                // Handle the click event on the disabled v-autocomplete
                console.log('Click event on disabled v-autocomplete');
                // Add your custom logic here
            },

            RESET_ALL_DATA(){ 
                try { 
                    Object.assign(this.$data, this.$options.data())  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'RESET_ALL_DATA',
                        page:PAGE_NAME, 
                    }) 
                }
            },

            INITIALIZE_SELLING_MODE(){
                try {
                    let CompanySettings = this.CompanySettings
                    if (!CompanySettings) {
                        
                    } else {
                        if (CompanySettings.both_selling_mode) {
                            this.selling_model = ""
                        }if (CompanySettings.only_retail_model) {
                            this.selling_model = "Retail Price"
                        }if (CompanySettings.only_wholesale_model) {
                            this.selling_model = "Wholesale Price"
                        }
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'INITIALIZE_SELLING_MODE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    const sold_price = this.toNumber(item.sold_price)
                    const discount = this.toNumber(item.discount)
                    const quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            ON_SELECT_ITEM(item,index){
                try {  
                    if (!this.longClickDetected) {
                        this.$emit("item_selected",item,index) 
                        this.ON_SELECT([item])
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            ON_SELECT(items){
                try {  
                    this.RESET_ALL_DATA()
                    this.$emit("selected",items)  
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SELECT',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            UPDATE_SELECTED_ITEM(){
                try { 
                    let input = this.input
                    // this.current_selected_item=item
                    this.dialog_edit_selected_item = false
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'UPDATE_SELECTED_ITEM',
                        page:PAGE_NAME, 
                    }) 
                } 
            },  
            CONFIRM_REMOVE_ITEM(selected_item,index){
                try { 
                    this.dialog_edit_selected_item = false
                    this.$emit("confirm_remove_item",selected_item,index)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CONFIRM_REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            REMOVE_ITEM(data){
                try { 
                    this.$emit("remove_item",data)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'REMOVE_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            CHECK_ITEM(item,index){
                try { 
                    // this.selected_items[index] = {
                    //     ...item,
                    //     checked: item.check? true :false
                    // }
                    console.log(item);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CHECK_ITEM',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FOCUS_INPUT(field){
                this.focused_field = field 
            },
            ON_SEARCH(){
                try {   
                    let active_model = this.active_model 
                    let bar_type = this.bar_type 
                    if (!active_model) { 
                        if (bar_type == "warehouse") {
                            this.menu_warehouse_mode = true
                        } else {
                            this.menu_selling_mode = true 
                        }
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_SEARCH',
                        page:PAGE_NAME, 
                    }) 
                } 
            },


            //---excel
            SELECT_ACTION(action,item){
                try { 
                    if (!action) {
                        
                    }else if (action.code == 1) {
                        this.SELECT_EXCEL() 
                    }else if (action.code == 2) {
                        this.EXPORT_EXCEL() 
                    }else {
                        
                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },
            FILE_SELECTED(wb){
                try {
                    this.WB = wb
                    this.excelSelector = false
                    this.EXCEL_DATA_ITEMS=[]  
                    let worksheet_item = wb.Sheets[this.MBS.actions.TEXT_UP0(this.VALUE_ITEMS)]  
                    if (!worksheet_item) {
                        this.input.check = true
                        this.MBS.actions.dialog({
                            show:true,
                            fixed:true,
                            title:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).title,
                            text:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).text,
                            btnYes:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnYes,
                            btnNo:this.MBS.text.working_sheet_not_found(this.NAME_ITEMS).btnNo,
                            action:{
                                code:PAGE_NAME+"=SELECT-EXCEL" 
                            }
                        })  
                    } else {
                        let json_item = this.XLSX.utils.sheet_to_json(worksheet_item)  
                        let data_item = []
                        let number = 0
                        json_item.forEach(excel_item => {
                            let dt = new Date
                            let date = dt.toISOString()

                            //CREATE ARRAY
                            let item_array = {}
                            this.FIELD_ITEMS.forEach(element => {
                                if (excel_item[element.value] && element.value) {
                                    item_array[element.value] = excel_item[element.value] 
                                }
                            }); 
                            //ADD
                            data_item.push({
                                ...item_array,
                                created_at:date, 
                                uid:this.us.uid,
                                no:number
                            })
                        }); 
                        this.EXCEL_DATA_ITEMS = data_item
                        this.dialog_selected_item = true 
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FILE_SELECTED',
                        page:PAGE_NAME, 
                    }) 
                }
            }, 
            //---------------[MAIN FUNCTIONS]------------   
            EXPORT_EXCEL(){
                try {   
                    console.log(this.excel_template);
                    this.MBS.events.$emit("EXPORT_EXCEL")
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXPORT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                } 
            },
            onPickFile(){ 
                this.MBS.events.$emit('SELECT_EXCEL')
            },
            LOADING_FILE(file){
                try {
                    this.excel_loading = true 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            ON_FILE_ERROR(error){
                try {
                    this.excel_loading = false 
                    console.log(error);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'LOADING_FILE',
                        page:PAGE_NAME, 
                    })
                } 
            },
            EXCEL_FILE_SELECTED(file,file_details,res){
                try {
                    this.loadingValue = 90
                    setTimeout(() => {
                        this.loadingValue = 100
                        setTimeout(() => { 
                            this.excel_loading = false
                            this.WB = file
                            this.WB_SHEETS = file?file.SheetNames:null
                            this.WB_PROPS = file?file.Props:null
                            this.WB_FILE_DETAILS = file_details
                            this.JSON_SHEETS = res?res.data:null 
                            let sheets = this.JSON_SHEETS
                            let itemsJS = null
                            if (sheets) {
                                sheets.forEach(element => {
                                    if (element.name == DATA.ITEMS.ITEMS.values ) {
                                        itemsJS = this.CREATE_SHEET_DATA(element.json,this.excel_template.headers)
                                    }
                                });
                            }  
                            if (itemsJS) {
                                let items = this.ItemsData
                                let selected_items = []
                                if (items) { 
                                    itemsJS.forEach(element => {
                                        let item = items.find(it=>{
                                            return it.code == element.code
                                        })
                                        if (item) {
                                            if (element.quantity) {
                                                selected_items.push({ 
                                                    // ...item,
                                                    name:item.name,
                                                    key:item.key,
                                                    code:item.code,
                                                    quantity:this.toNumber(element.quantity),
                                                    cost_price:this.toNumber(element.cost_price),
                                                    discount:this.toNumber(element.discount),
                                                }) 
                                            }
                                        }
                                    });
                                } 

                                this.$emit("imported_items",selected_items)
                                this.$emit("input",{
                                    selling_model:this.selling_model,
                                    warehouse_model:this.warehouse_model,
                                    import_model:this.import_model,
                                    imported_items:selected_items,
                                }) 

                               
                            } else {
                                this.MBS.actions.dialog({
                                    show:true,
                                    fixed:true,
                                    title:"FILE ERROR",
                                    text:"The selected file does not contain \"items\" sheet. Download, Receiving Stock Item Template. ",
                                    btnYes:'Download Template', 
                                    btnNo:'Already have', 
                                    action:{
                                        code:PAGE_NAME+"=DOWNLOAD-TEMPLATE"
                                    }
                                }) 
                            }
                        }, 500);
                    }, 1000);
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            NO_EXCEL_FILE_SELECTED(file){
                try {
                    this.excel_loading = false 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'NO_EXCEL_FILE_SELECTED',
                        page:PAGE_NAME, 
                    })
                } 
            },
            CREATE_SHEET_DATA (json, headers) {
                try {
                    if(!json||!headers){return null}
                    let data_item = []
                    let number = 0 

                    console.log(json,'json..............');
                    console.log(headers,'headers..............');
                    json.forEach(excel_item => {
                        let dt = new Date
                        let date = dt.toISOString()

                        //CREATE ARRAY
                        let item_array = {}
                        headers.forEach(element => {
                            if (excel_item[element.value] && element.value) {
                                item_array[element.value] = excel_item[element.value] 
                            }
                        }); 
                        //ADD
                        data_item.push({
                            ...item_array,
                            created_at:date, 
                            uid:this.us.uid,
                            no:number
                        })
                    }); 
                    return data_item
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CREATE_SHEET_DATA',
                        page:PAGE_NAME, 
                    })
                } 
            }, 
            SELECT_EXCEL(){  
                try { 
                    this.onPickFile()
                    // this.dialog_add_item = false
                    // this.excelSelector = !this.excelSelector
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'SELECT_EXCEL',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=DOWNLOAD-TEMPLATE') {
                            if (action.YES) {
                                setTimeout(() => {
                                    this.EXPORT_EXCEL()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else {
                                
                            }
                        }
                        if (action.code ===PAGE_NAME+'=CLEAR-SALES') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.CLEAR_ALL_DATA()
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }  
                        if (action.code ===PAGE_NAME+'=DELETE-ALL-ITEM-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ALL_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }   
                        if (action.code ===PAGE_NAME+'=DELETE-ITEM-ORDER') {
                            if (action.YES) {
                                setTimeout(() => {  
                                    this.DELETE_ITEM_ORDER(action.data)
                                }, this.MBS.data.ACTION_REFRESH_TIME);    
                            } else { 
                            }     
                        }   
                        if (action.code ===PAGE_NAME+'=RELOAD-DATA') {
                            if (action.YES) {
                                this.RELOAD_DATA()
                            } else {
                                
                            }
                        }  
                        
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            }, 
            ON_KEY_DOWN(key,time){
                try {
                    if (key=='Enter') {   

                    }
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'ON_KEY_DOWN',
                        page:PAGE_NAME, 
                    })  
                } 
            },
            MBS_KEYBOARD(key){ 
                let focused_field = this.focused_field
                const field_value = focused_field?focused_field.value:null

                const ref_inputs = this.$refs
                if (key ) {
                    let value = key.value 
                    if (field_value) { 
                        if (field_value == "current_selected_item_quantity") {
                            if (value=="BACK_SPACE") {
                                this.current_selected_item.quantity = (""+this.current_selected_item.quantity).slice(0,-1)
                            } else {
                                this.current_selected_item.quantity =this.current_selected_item.quantity+value 
                            }
                        }else{
                            if(!this.input){this.input={}}
                            if (value=="BACK_SPACE") {
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]+'':'').slice(0,-1)
                            }else{
                                this.input[field_value] =(this.input[field_value]?this.input[field_value]:'')+value
                                console.log((this.input[field_value]?this.input[field_value]:'')+value,'****......');
                            }
                            setTimeout(() => {
                                this.input = {...this.input}
                            }, 1);

                            console.log(this.input,'input......');
                            console.log(value,'value......');
                        }
                        
                    }
                }
            }, 

        },
        beforeDestroy(){
            try {
                this.MBS.events.$off('ON_KEY_DOWN', this.ON_KEY_DOWN);  
                this.MBS.events.$off('MBS_KEYBOARD', this.MBS_KEYBOARD);    
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'beforeDestroy',
                    page:PAGE_NAME, 
                })
            }
        },
        watch:{ 
            CompanySettings(value){
                if (value) {
                    this.INITIALIZE_SELLING_MODE() 
                }
            },
            item_multiple_select(value){
                this.$emit("item_multiple_select",value?true:false)
            },
            CheckedItems(value){
                this.$emit("checked_items",value)
            },
            search_item(value){ 
                if (value) { 
                    this.$emit("searched_item_key",value)
                    this.$emit("input",{
                        selling_model:this.selling_model,
                        warehouse_model:this.warehouse_model,
                        import_model:this.import_model,
                        searched_item_key:value,
                    }) 
                    setTimeout(() => {
                        this.search_item=''
                    }, 50);
                }
            }, 
            selling_model(value){ 
                this.$emit("input",{
                    selling_model:value,
                    warehouse_model:this.warehouse_model,
                    import_model:this.import_model,
                }) 
            },
            import_model(value){ 
                this.$emit("input",{
                    import_model:value,
                    warehouse_model:this.warehouse_model,
                    selling_model:this.selling_model,
                }) 
            }, 
            warehouse_model(value){ 
                this.$emit("input",{
                    warehouse_model:value,
                    selling_model:this.selling_model,
                    import_model:this.import_model,
                }) 
            }, 
        } 

    }
</script>
<style>
    .selected {
        background-color: #a8dadc;
    }
    .checked {
        background-color: #dadada;
    }
    .disabled-autocomplete {
        pointer-events: none; /* Disable pointer events on the autocomplete */
        opacity: 0.5; /* Optionally reduce the opacity to indicate it's disabled */
    }

    .click-capture-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        /* background-color: blue; Make it transparent */
        z-index: 0; /* Place it above the autocomplete */
    }
    
</style> 